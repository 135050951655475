<template>
  <div id="__next" data-reactroot="">
    <div class="DesktopHeader__Oval-sc-1uxzpou-5 geyhvA">
      <span
        style="
          box-sizing: border-box;
          display: inline-block;
          overflow: hidden;
          width: initial;
          height: initial;
          background: none;
          opacity: 1;
          border: 0;
          margin: 0;
          padding: 0;
          position: relative;
          max-width: 100%;
        "
        ><span
          style="
            box-sizing: border-box;
            display: block;
            width: initial;
            height: initial;
            background: none;
            opacity: 1;
            border: 0;
            margin: 0;
            padding: 0;
            max-width: 100%;
          "
          ><img
            style="
              display: block;
              max-width: 100%;
              width: initial;
              height: initial;
              background: none;
              opacity: 1;
              border: 0;
              margin: 0;
              padding: 0;
            "
            alt=""
            aria-hidden="true"
            src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27753%27%20height=%27645%27/%3e" /></span
        ><img
          alt="Oval Background"
          src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
          decoding="async"
          data-nimg="intrinsic"
          style="
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            box-sizing: border-box;
            padding: 0;
            border: none;
            margin: auto;
            display: block;
            width: 0;
            height: 0;
            min-width: 100%;
            max-width: 100%;
            min-height: 100%;
            max-height: 100%;
          " /><noscript
          ><img
            alt="Oval Background"
            srcSet="/_next/image?url=%2Fimages%2Foval.png&amp;w=828&amp;q=75 1x, /_next/image?url=%2Fimages%2Foval.png&amp;w=1920&amp;q=75 2x"
            src="/_next/image?url=%2Fimages%2Foval.png&amp;w=1920&amp;q=75"
            decoding="async"
            data-nimg="intrinsic"
            style="
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              box-sizing: border-box;
              padding: 0;
              border: none;
              margin: auto;
              display: block;
              width: 0;
              height: 0;
              min-width: 100%;
              max-width: 100%;
              min-height: 100%;
              max-height: 100%;
            "
            loading="lazy" /></noscript
      ></span>
    </div>
    <main class="Layout__Main-sc-5v9olf-0 geDkju">
      <div class="ContentSection__Wrapper-bj0yz-0 hTQNfT">
        <h2>Fun Word Puzzles People Love to play.</h2>
        <h4>
          We promise you’ll leave each game feeling accomplished, smarter, and
          more at peace! Word Search Journey is satisfying, challenging, and
          perfect new twist of a classic word search game and a world
          exploration you won’t want to put down!
        </h4>
      </div>
      <div class="IllustrationSection__Wrapper-sc-1mxbui0-0 gWDIuQ">
        <span
          style="
            box-sizing: border-box;
            display: inline-block;
            overflow: hidden;
            width: initial;
            height: initial;
            background: none;
            opacity: 1;
            border: 0;
            margin: 0;
            padding: 0;
            position: relative;
            max-width: 100%;
          "
          ><span
            style="
              box-sizing: border-box;
              display: block;
              width: initial;
              height: initial;
              background: none;
              opacity: 1;
              border: 0;
              margin: 0;
              padding: 0;
              max-width: 100%;
            "
          >
            <img
              style="
                display: block;
                max-width: 100%;
                width: initial;
                height: initial;
                background: none;
                opacity: 1;
                border: 0;
                margin: 0;
                padding: 0;
              "
              alt=""
              aria-hidden="true"
              src="@/assets/img/plantstory/bg-main.webp" /></span
          ><img
            alt="Background Image"
            src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
            decoding="async"
            data-nimg="intrinsic"
            style="
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              box-sizing: border-box;
              padding: 0;
              border: none;
              margin: auto;
              display: block;
              width: 0;
              height: 0;
              min-width: 100%;
              max-width: 100%;
              min-height: 100%;
              max-height: 100%;
            "
          />
        </span>
        <div class="phone">
          <span
            style="
              box-sizing: border-box;
              display: inline-block;
              overflow: hidden;
              width: initial;
              height: initial;
              background: none;
              opacity: 1;
              border: 0;
              margin: 0;
              padding: 0;
              position: relative;
              max-width: 100%;
            "
          >
            <span
              style="
                box-sizing: border-box;
                display: block;
                width: initial;
                height: initial;
                background: none;
                opacity: 1;
                border: 0;
                margin: 0;
                padding: 0;
                max-width: 100%;
              "
            >
              <img
                style="
                  display: block;
                  max-width: 100%;
                  width: initial;
                  height: initial;
                  background: none;
                  opacity: 1;
                  border: 0;
                  margin: 0;
                  padding: 0;
                "
                alt=""
                aria-hidden="true"
                src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%271455%27%20height=%271878%27/%3e"
            /></span>
            <img
              alt="APP Screenshot"
              src="@/assets/img/plantstory/phone.webp"
              decoding="async"
              data-nimg="intrinsic"
              style="
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                box-sizing: border-box;
                padding: 0;
                border: none;
                margin: auto;
                display: block;
                width: 0;
                height: 0;
                min-width: 100%;
                max-width: 100%;
                min-height: 100%;
                max-height: 100%;
              "
            />
          </span>
        </div>
      </div>
      <div class="DownloadSection__Wrapper-o1mqg3-0 ljmIgd">
<!--        <span>Download the App</span>-->
<!--        <div class="DownloadSection__DownloadLinks-o1mqg3-1 fQrfNs">-->
<!--          <a-->
<!--            href="https://apple.co/38GJRtW"-->
<!--            target="_blank"-->
<!--            rel="noreferrer"-->
<!--            class="DownloadSection__DownloadWrapper-o1mqg3-2 fAWuiD"-->
<!--          >-->
<!--            <svg-->
<!--              class=""-->
<!--              style="display: inline-block; vertical-align: middle"-->
<!--              width="20"-->
<!--              height="20"-->
<!--              viewBox="0 0 1024 1024"-->
<!--              xmlns="http://www.w3.org/2000/svg"-->
<!--            >-->
<!--              <path-->
<!--                d="M737.487 546.891c-1.014-114.715 92.317-170.524 96.585-173.122-52.858-78.41-134.785-89.124-163.575-89.978-68.809-7.368-135.555 41.892-170.607 41.892-35.751 0-89.729-41.181-147.903-39.97-74.861 1.175-144.895 45.274-183.305 113.754-79.269 139.629-20.15 344.82 55.796 457.684 37.99 55.311 82.382 116.993 140.487 114.822 56.846-2.349 78.080-36.874 146.679-36.874 67.97 0 87.909 36.874 147.169 35.45 61.008-0.925 99.419-55.489 136.080-111.298 43.902-63.284 61.533-125.748 62.233-128.952-1.399-0.463-118.449-45.914-119.638-183.408z"-->
<!--              ></path>-->
<!--              <path-->
<!--                d="M625.545 209.545c30.574-38.903 51.493-91.829 45.686-145.538-44.252 1.993-99.593 31.143-131.462 69.192-28.195 33.528-53.382 88.483-46.876 140.163 49.709 3.773 100.748-25.52 132.651-63.817z"-->
<!--              ></path>-->
<!--            </svg> </a-->
<!--          ><a-->
<!--            href="https://bit.ly/3ifz39d"-->
<!--            target="_blank"-->
<!--            rel="noreferrer"-->
<!--            class="DownloadSection__DownloadWrapper-o1mqg3-2 fAWuiD"-->
<!--          >-->
<!--            <svg-->
<!--              class=""-->
<!--              style="display: inline-block; vertical-align: middle"-->
<!--              width="20"-->
<!--              height="20"-->
<!--              viewBox="0 0 1024 1024"-->
<!--              xmlns="http://www.w3.org/2000/svg"-->
<!--            >-->
<!--              <path-->
<!--                d="M280.892 78.627c-31.989-17.681-69.547-17.272-101.332 0.256l372.52 343.649 125.144-125.144-396.333-218.76z"-->
<!--              ></path>-->
<!--              <path-->
<!--                d="M142.308 114.040c-9.096 14.87-14.308 31.887-14.308 49.925v620.663c0 17.476 4.752 34.135 13.337 48.698l374.616-374.616-373.645-344.671z"-->
<!--              ></path>-->
<!--              <path-->
<!--                d="M843.147 388.959l-119.37-65.868-134.138 134.087 164.389 151.614 89.17-49.209c32.091-17.783 51.305-49.669 51.305-85.337-0.051-35.668-19.214-67.554-51.356-85.286z"-->
<!--              ></path>-->
<!--              <path-->
<!--                d="M553.511 493.357l-375.638 375.638c16.301 9.3 34.186 14.206 52.173 14.206 17.374 0 34.85-4.395 50.845-13.235l426.022-235.112-153.403-141.496z"-->
<!--              ></path>-->
<!--            </svg>-->
<!--          </a>-->
<!--        </div>-->
      </div>
    </main>
    <div class="Layout__Preload404-sc-5v9olf-1 gRtPpV">
      <a href="/404">Preload 404</a>
    </div>
    <footer class="Footer__Wrapper-ce54pf-0 eLlWjo">
      <div class="Footer__InfoWrapper-ce54pf-4 iYxkao">
        <a
          href="mailto:help@flamezonex.com"
          class="MediaIcon__WrapperLink-lhc0ei-0 SZQie"
        >
          Get Support
          <svg
            class=""
            style="display: inline-block; vertical-align: middle"
            width="20"
            height="20"
            viewBox="0 0 1024 1024"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              style="fill: #303332"
              d="M977.455 244.388v544.337c0 27.094-22.048 49.093-48.75 49.093h-833.406c-12.993 0.013-25.453-5.166-34.608-14.385s-14.247-21.715-14.144-34.708v-544.337l429.051 386.082c20.235 18.275 52.719 18.128 72.807 0l429.050-386.082zM83.050 171.581c-20.137-17.638-14.405-31.945 12.249-31.945h833.406c26.947 0 32.19 14.503 12.249 31.945l-392.548 343.358c-21.183 17.564-51.868 17.564-73.052 0l-392.303-343.358z"
            ></path>
          </svg>
        </a>
      </div>
      <div class="Footer__Content-ce54pf-1 fIWZqV">
        <p class="Footer__Copy-ce54pf-2 Footer__Terms-ce54pf-3 crOqlK sAyyj">
          <a href="/about-terms">Terms of Conditons  </a>
          <!-- -->|<!-- -->
          <a href="/about-privacy">  Privacy Policy</a>
        </p>
      </div>
    </footer>
    <div class="Loading__Wrapper-sc-1qfl0q-0 dPXPca">
      <div
        style="
          width: 60px;
          height: 60px;
          overflow: hidden;
          margin: 0 auto;
          outline: none;
        "
        title=""
        role="button"
        aria-label="animation"
        tabindex="0"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "home",
};
</script>

<style scoped></style>
